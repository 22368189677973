import React from 'react'

function Loader()
{
  return (
    <div>
      </div>
      )
}

export default Loader;
